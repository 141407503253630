import React, { Component } from 'react';

class Kontakt extends Component {
  render() {
    return (
        <div id="contact">
            <h2 className="handle">
              <a href="#">Kontakt &bull; Impressum &bull; Datenschutz</a>
            </h2>
            <div className="contact-info hide">
              <div className="four spacing"></div>
              <div className="row ">
                <div className="large-7 columns">
                  <div className='form'>
                    <form id='contact_form' className='dark' method='POST'>
                      <p id='thanks' className='hide'>
                        Danke, dass du uns kontaktiert hast. Wir werden uns umgehend mit Dir auseinandersetzen.
                      </p>
                      <input className='required' name='name' placeholder='NAME' type='text'/>
                      <input className='required email' name='email' placeholder='E-MAIL' type='text'/>
                      <input className='required' name='subject' placeholder='BETREFF' type='text'/>
                      <textarea className='required' name='message' placeholder='NACHRICHT'></textarea>
                      <div className='spacing'></div>
                      <input className='button white small' type='submit' />
                    </form>
                  </div>
                </div>
                <div className="large-4 columns">
                  <div className="contact-details">
                    <h4>Adresse:</h4>

                    <p>
                    R&amp;R GmbH Bremen<br/>
                    Am Tabakquartier 60<br/>
                    Tabakspeicher 9<br/>
                    28197 Bremen</p>
                    <h4>Telefon:</h4>
                    <p>+49 (0) 421 52150 0</p>
                    <h4>E-Mail:</h4>
                    <p><a href="mailto:info@rr-bremen.de">info@rr-bremen.de</a></p>
                    
                    <h4>Registereintrag:</h4>
                    <p>
                      Eintragung im Handelsregister.
                      <br />Registergericht: Gerichtsstand Bremen
                      <br />Registernummer: HRB 10 624
                      <br />Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                      <br />DE 17 400 7730
                    </p>
                    <h4>Gesch&auml;ftsführer:</h4>
                    <p>Herr Ken-P. Jachmann & <br/> Herr Marc Jachmann</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="large-12 columns">
                  <h3 style={{color: "#fff"}}>Datenschutz</h3>
                  <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    <br />
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                    <br />
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                  </p>
                  <h4 style={{color: "#fff"}}>Datenschutzerklärung für die Nutzung von Matomo</h4>
                  <p>
                    Diese Website benutzt den Open Source Webanalysedienst Matomo. Matomo verwendet sog. "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Dazu werden die durch den Cookie erzeugten Informationen über die Benutzung dieser Website auf unserem Server gespeichert. Die IP-Adresse wird vor der Speicherung anonymisiert.
                    <br />
                    Die durch den Cookie erzeugten Informationen über die Benutzung dieser Website werden nicht an Dritte weitergegeben. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                    <br />
                    Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die Speicherung und Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt der verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen hat dies zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten Beuch unserer Seite wieder aktiviert werden
                  </p>
                  <iframe
                    style={{border: 0, height: 200, width: 600}}
                    title="Matomo Opt-Out"
                    src="https://www.rr-bremen.de/piwik/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=333333&fontColor=666666&fontSize=1rem&fontFamily=Raleway%2C%20%22Helvetica%20Neue%22%2C%20Helvetica%2C%20Roboto%2C%20Arial%2C%20sans-serif"></iframe>

                  <h4 style={{color: "#fff"}}>Auskunft, Löschung, Sperrung</h4>
                  <p>
                    Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden
                  </p>
                  <h4 style={{color: "#fff"}}>Server-Log-Files</h4>
                  
                    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                    <ul>
                      <li>Browsertyp/ Browserversion</li>
                      <li>verwendetes Betriebssystem</li>
                      <li>Referrer URL</li>
                      <li>Hostname des zugreifenden Rechners</li>
                      <li>Uhrzeit der Serveranfrage</li>
                    </ul>
                    <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>

                  <h4 style={{color: "#fff"}}>Cookies</h4>
                  <p>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                    <br />
                    Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                    <br />
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                  </p>
                  <h4 style={{color: "#fff"}}>Kontaktformular</h4>
                  <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                  </p>
                </div>
              </div>
              <div className="two spacing"></div>
            </div>
          </div>
    )
  }
}

export { Kontakt }