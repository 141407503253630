import React, { Component } from 'react';
import Content from './Content';

class Mehr extends Component {
  render() {
    return (
      <div id="mehr" className="box">
        <div className="header">
          <div className="special-title">
            <div id="mehr-img"></div>
            <img src="images/header/und_mehr.png" id="__mehr" className="header-img" alt=""/>
          </div>
          <div className="desc desc-4 invisible">
            <h2 style={{ lineHeight: "1em" }}>
              Wir geben alles. Und noch mehr.
            </h2>
            <p>
              Unseren Kunden bieten wir mehr als klassische Agenturleistungen. Full Service – weiter gedacht. Und wie können wir Ihnen helfen?
            </p>
          </div>
        </div>
        <Content/>
      </div>

    )
  }
}

export { Mehr }