import React, { Component } from 'react';
import Content from './Content';

class StrategieKreation extends Component {
  render() {
    return (
      <div id="strategie-kreation" className="box">
        <div className="header">
          <div className="special-title">
            <div id="strategie-kreation-img"></div>
            <img src="images/header/strategie_und_design.png" id="__strategie-kreation" className="header-img" alt="" />
          </div>
          <div className="desc desc-2 invisible">
            <h2 style={{color: '#333'}}>
              Wir tun, was wir können.
            </h2>
            <p style={{color: '#333'}}>
              Und das ist einiges: Von der Strategie über die Ideenfindung bis zur Produktion und Distribution in allen Kanälen.
            </p>
          </div>
        </div>
        <Content/>
      </div>

    )
  }
}

export { StrategieKreation }