import React, { Component } from 'react';

function Image ({src, utm}) {
  if(utm)
    return(
      <li>
        <a href={utm} target="_blank" rel="noopener noreferrer">
          <img src="images/placeholder.jpg" srcSet={`images/foto_video/${src} 1x, images/foto_video/${src.replace(".jpg","@2x.jpg")} 2x`} alt="" />
        </a>
      </li>
    )

  return(
    <li>
      <img src="images/placeholder.jpg" srcSet={`images/foto_video/${src} 1x, images/foto_video/${src.replace(".jpg","@2x.jpg")} 2x`} alt="" />
    </li>
  )
}

function Video ({src, width, height, title}) {
  return(
    <li>
      <div className={`responsive-video${(width === height) ? "-1by1" : ""}`}>
        <iframe src={`https://player.vimeo.com/video/${src}?title=0&byline=0&portrait=0&autoplay=0&dnt=true`} title={title} width={width} height={height} frameBorder="0" allowFullScreen></iframe>
      </div>
    </li>
  )
}

class Content extends Component {
 
  render() {
    return (
            <div className="content invisible">
              <div className="four spacing"></div>
              <div className="two spacing"></div>
              <div className='row'>
                <div className='large-12 columns'>
                
                  <div className='post'>
                    <div className="image">
                      <img alt="" src="images/placeholder.jpg" srcSet="images/RR_Rubrik_Foto.jpg 1x, images/RR_Rubrik_Foto@2x.jpg 2x" />
                    </div>
                    <div className='content'>
                      <h3>Fotografie</h3>
                      <p className='info'>
Tabletop-Fotografie machen wir in unserem Studio mit moderner Küche und Food Styling. Beide Studios befinden sich direkt bei uns im Gebäude – so können Fotografen direkt mit unseren Designern zusammenarbeiten.
                      </p>
                    </div>
                  </div>

                  <div className='post'>
                    <div className="image">
                      <img alt="" src="images/placeholder.jpg" srcSet="images/RR_Rubrik_Film.jpg 1x, images/RR_Rubrik_Film@2x.jpg 2x" />
                    </div>
                    <div className='content'>
                      <h3>Film</h3>
                      <p className='info'>
Wir könnten durchdrehen. Vor Glück! Denn noch nie war der Bedarf an Bewegtbildmaterial so groß wie heute. Und wir produzieren für unsere Kunden routiniert TV-Spots, OLV, Dokumentationen, Schulungsfilme und noch mehr.
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            <div className="full">
              <div className='row'>
                <div className="two spacing"></div>
                <div className="row">
                  <div className="large-12 columns">
                    <div className="section-title">
                      <h2>Beispiele? Aber gerne!</h2>
                    </div>
                  </div>
                </div>
                <div className='large-12 columns'>
                  <div className='masonry-gallery' data-gutter="" id="masonry-gallery">

                    <ul className='gallery'>

                      <Image src="bild_1_3x2.jpg"/>
                      {/*<Image src="bild_2_2x3.jpg"/>*/}
                      <Image src="bild_2_2x3_2.jpg"/>
                      <Image src="bild_3_3x2.jpg"/>
                      <Image src="bild_4_9x16.jpg"/>
                      <Image src="bild_5_1x1.jpg"/>
                      <Image src="r_r_food_shooting_001.jpg"/>
                      <Image src="r_r_food_shooting_002.jpg"/>
                      <Image src="r_r_food_shooting_003.jpg"/>
                      <Image src="r_r_food_shooting_004.jpg"/>
                      <Image src="r_r_food_shooting_005.jpg"/>
                      <Image src="r_r_food_shooting_006.jpg"/>
                      <Image src="r_r_food_shooting_007.jpg"/>
                      <Image src="r_r_food_shooting_008.jpg"/>

{/*
                      <Image src="Getoastete_Suesskartoffeln_mit_Koernigem_Frischkaese.jpg" utm="https://www.milram.de/rezept/getoastete-suesskartoffeln-mit-koernigem-frischkaese/?utm_campaign=2019_weiss_RundRReferenzen_KoernigerFrischkaese&utm_source=rr-bremen.de&utm_medium=referral&utm_content=Rezept-GetoasteteSuesskartoffelnMitKoernigemFrischkaese" />
                      <Image src="LIEKEN_L_S_Moodaufnahmen_05_1815379_Mohnzauber.jpg" />
                      <Image src="MILRAM_Kalder_Kaffee_Brownies_mit_Salzmandeln.jpg" utm="https://www.milram.de/rezept/kaffee-brownies-mit-salzmandeln/?utm_campaign=2019_weiss_RundRReferenzen_KalderKaffee&utm_source=rr-bremen.de&utm_medium=referral&utm_content=Rezept-BrowniesMitSalzmandeln" />
                      <Image src="MILRAM_Kalder_Kaffee_Stieleis_mit_Fruchtpueree_und_Kakaonibs.jpg" utm="https://www.milram.de/rezept/kaffee-stieleis-mit-fruchtpueree-und-kakaonibs/?utm_campaign=2019_weiss_RundRReferenzen_KalderKaffee&utm_source=rr-bremen.de&utm_medium=referral&utm_content=Rezept-KaffeeStieleis" />
                      <Image src="MILRAM_KK_ColdBrew_V01.jpg" utm="https://www.milram.de/kalder-kaffee/?utm_campaign=2019_weiss_RundRReferenzen_KalderKaffee&utm_source=rr-bremen.de&utm_medium=referral&utm_content=Foto-KalderKaffee" />
                      <Video src="335130840" title="Katjes_Rezeptvideos_cheesecream" width="900" height="900" />
                      <Image src="sallos_brownie.jpg" />

                      <Video src="335130964" title="MILRAM-Rezept-overnightoats" width="1600" height="900" />
                      <Video src="335130738" title="DMK_MILRAM_KK_kalder-hund" width="1600" height="900" />

                      <Image src="sallos_italienische_Kuechlein.jpg" />
                      <Image src="Wuerzig-kraeftiges_Kaesefondue_mit_Weisswein.jpg" utm="https://www.milram.de/rezept/wuerzig-kraeftiges-kaesefondue-mit-weisswein/?utm_campaign=2019_gelb_RundRReferenzen_MueritzerHerzhaft&utm_source=rr-bremen.de&utm_medium=referral&utm_content=Rezept-WuerzigkraeftigesKaesefondueMitWeisswein" />
*/}
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
      )
  }
}

export default Content;