import React, { Component } from 'react';
// import JSONPretty from 'react-json-pretty';
// import 'react-json-pretty/themes/monikai.css';
// import CoffeeCharts from './CoffeeCharts';

const Member = ({data}) => {
  const image = data.email.substring(0,data.email.indexOf("@"));
  return(
    <div className="large-4 columns">
      <div className="member">
        <img src={`images/team/${image}.jpg`} srcSet={`images/team/${image}.jpg 1x, images/team/${image}@2x.jpg 2x`} alt={`${data.vorname} ${data.nachname}`} />
        <div className="letter-overlay" style={{color: data.color}}>{data.vorname.substring(0,1)}</div>
        <h4>{data.vorname} {data.nachname}</h4>
        <p className='position'>{data.position}</p>
        <ul>
          <li><a href={`tel:+${data.telefon.replace("(0)","").replace(/\D+/g, '')}`}><i className='fa fa-phone'></i> {data.telefon}</a></li>
          <li><a href={`mailto:${data.email}?subject=Hallo ${data.vorname}`}><i className='fa fa-envelope'></i> {data.email}</a></li>
        </ul>
      </div>
    </div>
  );
}

class Content extends Component {
  constructor(props){
    super(props);
    // this.setStats = this.setStats.bind(this);
  }

  state = {
    mitarbeiter: [],
    chefs: [],
    codeVisible: false,
    code: "codecode"
  }

  componentDidMount(){
    fetch('data/mitarbeiter.json')
    .then(response => response.json())
    .then(response => {
      this.setState({
        mitarbeiter: response.mitarbeiter.sort(function(r,n){return r.nachname<n.nachname?-1:r.nachname>n.nachname?1:0}),
        chefs: response.chefs.sort(function(r,n){return r.nachname<n.nachname?-1:r.nachname>n.nachname?1:0})
      });
    });
  }

  // setStats(stats) {
  //   this.setState({code: JSON.stringify(stats)})
  //   // console.log({stats});
  // }

  render() {
    const { mitarbeiter, chefs, codeVisible, code } = this.state;
    const members = [...mitarbeiter, ...chefs];
    return (
      <div className="content invisible">
        <div className="two spacing"></div>
        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2>Wer is' das eigentlich?</h2>
            </div>
          </div>
        </div>
        <div className="two spacing"></div>

        {members.map((m,i) => (
          <Member data={m} key={i} />
        ))}


        <div className="four spacing"></div>
        
{/*
        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2>Kaffeemaschinen Live-Statistik <i className='fa fa-info-circle' onClick={() => this.setState({ codeVisible: !codeVisible })}></i></h2>
            </div>
            { codeVisible && 
              <JSONPretty id="json-pretty" data={code}></JSONPretty>
            }
          </div>
        </div>


        <div className="row">
          <div className='mod testimonials'>
            <div className='items'>
             <CoffeeCharts setStats={this.setStats}/>
            </div>
          </div>
        </div>

        <div className="two spacing"></div>
*/}        
        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2 style={{fontSize: "1.5em"}}>Wir sind ausgezeichnet!</h2>
            </div>
            <div className="section-title">
              <a href="https://www.ausgezeichnet-familienfreundlich.de/wir-sind-ausgezeichnet.html#anchor84" target="_blank" className="link">
                <img src="images/ausgezeichnet_familienfreundlich.png" alt="ausgezeichnet familienfreundlich logo" style={{maxWidth: "50%", marginBottom: 50}}/>
              </a>
            </div>
          </div>
        </div>

        <div className="two spacing"></div>

        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2 style={{fontSize: "1.5em"}}>Das „&“ steht für unsere Kunden.</h2>
            </div>
            <div className="section-title">
              <h2>Mit denen wir zum Teil seit Jahrzehnten eng und freundschaftlich zusammenarbeiten.</h2>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='large-12 columns'>
            <div className='clients'>
              <div><img alt="milram" src="images/clients/milram.jpg"/></div>
              <div><img alt="milka" src="images/clients/milka.jpg"/></div>
              <div><img alt="jacobs" src="images/clients/jacobs.jpg"/></div>
              <div><img alt="senseo" src="images/clients/senseo.jpg"/></div>
              <div><img alt="tassimo" src="images/clients/tassimo.jpg"/></div>
              <div><img alt="philadelphia" src="images/clients/philadelphia.jpg"/></div>
              <div><img alt="miracel whip" src="images/clients/miracel_whip.jpg"/></div>
              <div><img alt="bulls" src="images/clients/bulls-eye.jpg"/></div>
              <div><img alt="nordsee" src="images/clients/nordsee.jpg"/></div>
              <div><img alt="lieken" src="images/clients/lieken.jpg"/></div>
              <div><img alt="lantmaennen" src="images/clients/lantmaennen.jpg"/></div>
              <div><img alt="kornmark" src="images/clients/kornmark.jpg"/></div>
              <div><img alt="symrise" src="images/clients/symrise.jpg"/></div>
              <div><img alt="pedigree" src="images/clients/pedigree.jpg"/></div>
              <div><img alt="whiskas" src="images/clients/whiskas.jpg"/></div>
              <div><img alt="siemens" src="images/clients/siemens.jpg"/></div>
              <div><img alt="universum" src="images/clients/universum.jpg"/></div>
              <div><img alt="buergschaftsbank" src="images/clients/buergschaftsbank.jpg"/></div>
              <div><img alt="roha" src="images/clients/roha.jpg"/></div>
              <div><img alt="gfm" src="images/clients/gfm.jpg"/></div>
              <div><img alt="appel" src="images/clients/appel.jpg"/></div>
              <div><img alt="buss" src="images/clients/buss.jpg"/></div>
            </div>
          </div>
        </div>
        <div className="four spacing"></div>

        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2 className="h2-r">Unser Wohlfühlfreiraumkreativstudio.</h2>
            </div>
            <div className="section-title">
              <h2>In unserer Agentur gibt es genug Platz zum Denken, Spielen und Arbeiten.</h2>
            </div>
          </div>
        </div>
        
        <div className="studio-images full no-overflow no-padding">
          <div className="large-4 columns no-padding">
            <img alt="" src="images/placeholder.jpg" srcSet="images/1_rr_tabakquartier_002.jpg 1x, images/1_rr_tabakquartier_002@2x.jpg 2x" />
          </div>
          <div className="large-4 columns no-padding">
            <img alt="" src="images/placeholder.jpg" srcSet="images/2_rr_tabakquartier_004.jpg 1x, images/2_rr_tabakquartier_004@2x.jpg 2x" />
          </div>
          <div className="large-4 columns no-padding">
            <img alt="" src="images/placeholder.jpg" srcSet="images/3_neues_studio_2.jpg 1x, images/3_neues_studio_2@2x.jpg 2x" />
          </div>
        </div>
      </div>
    )
  }
}

export default Content;