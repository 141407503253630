import React, { Component } from 'react';

class Content extends Component {
 
  render() {
    return (
      <div className="content invisible">
        <div className="four spacing"></div>
        <div className="full">
{/*        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2>Our services</h2>
            </div>
          </div>
        </div> */}
        <div className='row'>
          <div className="main-services no-overflow">
            <div className='large-6 columns'>
              <div className='mod icon-text'>
                <div className='icon-text-advanced'>
                  <div className='wrapper'>
                    <div className='content'>
                      <i className='fa fa-server'></i>
                    </div>
                  </div>
                  <h3>Digital Asset Management</h3>
                  <p>Effiziente und qualitativ hochwertige Aufbereitung, Verwaltung und Bereitstellung von großen Datenmengen. Zum Beispiel Mediadaten wie Logos, Packshots, Anzeigenlayouts etc. So stellen wir jederzeit schnellen Zugriff auf die aktuellsten, korrekten Daten sicher.</p>
                  <div className="spacing"></div>
                </div>
              </div>
            </div>
            <div className='large-6 columns'>
              <div className='mod icon-text'>
                <div className='icon-text-advanced'>
                  <div className='wrapper'>
                    <div className='content'>
                      <i className='fa fa-code'></i>
                    </div>
                  </div>
                  <h3>Software-Entwicklung</h3>
                  <p>Individuelle Webanwendungen, zugeschnitten auf die Bedürfnisse und Anforderungen unserer Kunden. Von kleinen Web-Apps bis hin zu anspruchsvollen Systemlösungen – wir programmieren für Sie eine maßgeschneiderte Lösung, basierend auf modernsten Web-Technologien und innovativen Frameworks.</p>
                </div>
              </div>
            </div>
            <div className='large-6 columns'>
              <div className='mod icon-text'>
                <div className='icon-text-advanced'>
                  <div className='wrapper'>
                    <div className='content'>
                      <i className='fa fa-calendar-check'></i>
                    </div>
                  </div>
                  <h3>Messen &amp; Events</h3>
                  <p>Jahrestagungen und Produktpräsentationen, Verkostungsaktivitäten, Messen und Events. Wir sind gerne für unsere Kunden im Marketing- und Salesbereich unterwegs.</p>
                  <div className="spacing"></div>
                </div>
              </div>
            </div>
            <div className='large-6 columns'>
              <div className='mod icon-text'>
                <div className='icon-text-advanced'>
                  <div className='wrapper'>
                    <div className='content'>
                      <i className='fa fa-cube'></i>
                    </div>
                  </div>
                  <h3>Produktion</h3>
                  <p>Produktmuster für den Vertrieb, Foto- und Filmaufnahmen. Prototypen für Displays, Präsentationsunterlagen für interne Zwecke oder Messen und auch Sales-Materialien produzieren wir inhouse für Sie. Als Einzelstück oder in höherer Auflage.</p>
                  <div className="spacing"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="four spacing"></div>
        </div>
      </div>
      )
  }
}

export default Content;