import React from 'react';

function CloseBtn(){
  return (
    <nav>
      <a href="#" id='back' className="hide nav-card" data-name="home">
        <i className="fa fa-times"></i>
      </a>
    </nav>
  )
}


export { CloseBtn }