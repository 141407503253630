import React, { Component } from 'react';

class Content extends Component {
 
  render() {
    return (
      <div className="content invisible">
        <div className="four spacing"></div>
{/*        <div className="row">
          <div className="large-12 columns">
            <div className="section-title">
              <h2>The process</h2>
            </div>
          </div>
        </div>
*/}
        <div className='row'>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='0'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_strategie_1.png" alt="" />
                  </div>
                </div>
                <h3>Strategie &amp; Konzeption</h3>
                <p>Ein guter Plan ist mehr als eine Idee. Darum setzen wir auf strategisches Marketing und ganzheitliche Konzeption – individuell angepasst an relevante Zielgruppen und Kanäle.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='100'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_packaging.png" alt="" />
                  </div>
                </div>
                <h3>Packaging Design <br/>&nbsp;</h3>
                <p>Auf den Inhalt kommt es an. Aber ohne gutes Packaging Design greift heute kein Kunde mehr zu einem Produkt. Gut, dass das zu unseren Kernkompetenzen gehört.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='200'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_pos_1.png" alt="" />
                  </div>
                </div>
                <h3>POS<br/>&nbsp;</h3>
                <p>Am POS gelten eigene Regeln: Innerhalb weniger Sekunden müssen Emotionen und Aufmerksamkeit geweckt werden. Mit direkten, reduzierten und verständlichen Visuals und Texten lassen wir die Produkte unserer Kunden im Einkaufswagen der Verbraucher landen.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='0'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_endverbraucher.png" alt="" />
                  </div>
                </div>
                <h3>Konsumenten-Kommunikation</h3>
                <p>In einer Flut von Werbebotschaften schaffen wir beim Endverbraucher Aufmerksamkeit und Awareness. Und zwar mit guten Ideen.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='100'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_fachhandels_kommunikation.png" alt="" />
                  </div>
                </div>
                <h3>Fachhandels-Kommunikation</h3>
                <p>Wir wissen, wie der Handel tickt. Und erreichen ihn mit Sell-in Maßnahmen von Fachanzeigen über Sales-Materialien bis hin zu Produktmustern für die optimale Ansprache. </p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='200'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_digital.png" alt="" />
                  </div>
                </div>
                <h3>Digital</h3>
                <p>Von wegen Neuland. Wer Full Service sagt, meint heute auch das Internet. Wir helfen unseren Kunden dabei, Menschen über digitale Kanäle zu erreichen. Zum Beispiel mit Websites, Display Advertising, Videos und Motion Design.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='0'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_social_media.png" alt="" />
                  </div>
                </div>
                <h3>Social Media Management</h3>
                <p>In den schnelllebigen Sozialen Medien bleibt wenig Zeit für Experimente. Darum bieten wir die professionelle, umfassende Betreuung Ihrer Marke auf Facebook, Instagram, YouTube & Co.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
          <div className='large-4 columns'>
            <div className='moveup' data-delay='100'>
              <div className='icon-text-diamond'>
                <div className='wrapper'>
                  <div className='content'>
                    <img src="images/icons/icon_cgi.png" alt="" />
                  </div>
                </div>
                <h3>CGI</h3>
                <p>Mehr als die Alternative zum Foto-Shooting: Wir setzen für unsere Kunden professionelle CGI-Projekte inhouse um. Von 3D-Packshots über 3D-Modeling bis zu 3D-Visualisierungen.</p>
              </div>
              <div className='two spacing'></div>
            </div>
          </div>
        </div>

        <div className="four spacing"></div>
      </div>
      )
  }
}

export default Content;