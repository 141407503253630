import React, { Component, useState } from 'react';
import { useCookies } from 'react-cookie';
import { WirAgentur } from './components/WirAgentur';
import { StrategieKreation } from './components/StrategieKreation';
import { FilmFotografie } from './components/FilmFotografie';
import { Mehr } from './components/Mehr';
import { Logo } from './components/Logo';
import { Kontakt } from './components/Kontakt';
import { CloseBtn } from './components/CloseBtn';
import './App.css';

function CookieBanner({checkbox, handleChange, onAccept}) {
  return (
    <div className="cookie-container">
      <p>Wir nutzen Cookies auf unserer Webseite. Einige davon sind essenziell, während andere uns helfen, unsere Webseite und deine Nutzererfahrung zu verbessern.</p>
      <div className="inputs">
        <input type="checkbox" id="essential" name="essential" onChange={handleChange} checked={!!checkbox.essential}/> <label htmlFor="essential">Essenziell</label>
        <input type="checkbox" id="statistic" name="statistic" onChange={handleChange} checked={!!checkbox.statistic}/> <label htmlFor="statistic">Statistiken</label>
        <input type="checkbox" id="externalmedia" name="externalmedia" onChange={handleChange} checked={!!checkbox.externalmedia}/> <label htmlFor="externalmedia">Externe Medien</label>
        <button className="small" onClick={onAccept}>akzeptieren</button>
      </div>
    </div>
  );
}

// class App extends Component {
//   state = {
//     count: 0,
//     active: null,
//     cookies: {
//       accepted: !!Boolean(this.props.cookies.get('COOKIES_ACCEPTED')),
//       essential: true,
//       statistic: false,
//       externalmedia: false,
//     }
//   };

//   handleChange(e) {
//     // console.log(e.target.name,e.target.checked)
//     const { cookies } = this.state;
//     cookies[e.target.name] = e.target.checked;
//     this.setState({...this.state, cookies});
//     console.log(this.state);
//   }
  
//   handleClick(){
//     this.setState({count: this.state.count+1 });
//     console.log(this.state);
//   }
  
//   componentDidMount() {
//     console.log(this.props.cookies);
//     console.log(this.state);
//   }

//   handleAccept() {
//     const d = Date.now();
//     const { cookies } = this.state;
//     this.props.cookies.set('COOKIES_ACCEPTED', d, { path: '/' });
//     this.props.cookies.set('COOKIES_ESSENTIAL', cookies.essential ? d : false, { path: '/' });
//     this.props.cookies.set('COOKIES_STATISTIC', cookies.statistic ? d : false, { path: '/' });
//     this.props.cookies.set('COOKIES_EXTERNALMEDIA', cookies.externalmedia ? d : false, { path: '/' });
//     this.setState({...this.state, cookies: {...cookies, accepted: true}});
//   }
// }



function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['COOKIES_ACCEPTED', 'COOKIES_ESSENTIAL', 'COOKIES_STATISTIC', 'COOKIES_EXTERNALMEDIA']);
  const [checkbox, setCheckbox] = useState({
    accepted: !!Boolean(cookies['COOKIES_ACCEPTED']),
    essential: true,
    statistic: false,
    externalmedia: false,
  });
  const [active, setActive] = useState(null);

  const handleChange = e => {
    const newCheckbox = {...checkbox}
    newCheckbox[e.target.name] = e.target.checked;
    setCheckbox(newCheckbox);
  }

  const handleAccept = e => {
    const d = Date.now();
    setCookie('COOKIES_ACCEPTED', d, { path: '/' });
    setCookie('COOKIES_ESSENTIAL', checkbox.essential ? d : false, { path: '/' });
    setCookie('COOKIES_STATISTIC', checkbox.statistic ? d : false, { path: '/' });
    setCookie('COOKIES_EXTERNALMEDIA', checkbox.externalmedia ? d : false, { path: '/' });
    const newCheckbox = {...checkbox};
    newCheckbox['accepted'] = true;
    setCheckbox(newCheckbox);

  }


  return (
    <div className="App">
    {!checkbox.accepted && 
      <CookieBanner
        onAccept={handleAccept}
        onAcceptStatistics={() => {}}
        checkbox={checkbox}
        handleChange={handleChange}
      />
    }
      <WirAgentur />
      <StrategieKreation/>
      <FilmFotografie/>
      <Mehr/>
      <Logo/>
      <Kontakt/>
      <CloseBtn/>
    </div>
  );
}

// export default withCookies(App);
export default App;
