import React, { Component } from 'react';
import './WirAgenture.css';
import Content from './Content';

class WirAgentur extends Component {
  render() {
    return (
      <div id="wir-agentur" className="box">
        <div className="header">
          <div className="special-title">
            <div id="wir-agentur-img"></div>
            <img src="images/header/wir_und_agentur.png" id="__wir-agentur" className="header-img" alt=""/>
          </div>
          <div className="desc desc-1 invisible">
            <h2>
              Wir machen das schon.
            </h2>
            <p>
              Und zwar eine ganze Weile: Strategie und Produktion aus einer Hand. Full Service mit der vollen Erfahrung aus 30 Jahren, in denen wir viel Herzblut in die Projekte unserer Kunden gesteckt haben. Und kein bisschen leiser geworden sind.
            </p>
          </div>
        </div>
        <Content/>
      </div>
    )
  }
}

export { WirAgentur }