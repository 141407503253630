import React, { Component } from 'react';
import Content from './Content';

class FilmFotografie extends Component {
  render() {
    return (
      <div id="film-fotografie" className="box">
        <div className="header">
          <div className="special-title">
            <div id="film-fotografie-img"></div>
            <img src="images/header/video_und_fotografie.png" id="__film-fotografie" className="header-img" alt=""/>
          </div>
          <div className="desc desc-3 invisible">
            <h2 style={{ color: "#333" }}>Das kann man sehen.</h2>
            <p style={{ color: "#333" }}>
              Foto- und Videoproduktionen realisieren wir seit Anfang an – von der Konzeption bis zum finalen Bildmaterial. Und weil es geht, produzieren wir gleich hier vor Ort – in einem unserer hauseigenen Fotostudios.
            </p>
          </div>
        </div>
        <Content/>
      </div>
    )
  }
}

export { FilmFotografie }